import { render } from "./AppForm.vue?vue&type=template&id=16af9340&scoped=true"
import script from "./AppForm.vue?vue&type=script&lang=ts"
export * from "./AppForm.vue?vue&type=script&lang=ts"

import "./AppForm.vue?vue&type=style&index=0&id=16af9340&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-16af9340"

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QSelect,QItem,QItemSection,QInput,QBadge,QAvatar,QSpace,QToggle,QBtn});
